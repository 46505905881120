import React, { useState } from 'react';
import { TextField, Button, Container, Paper, Grid } from '@mui/material';
// import logo from './logo_2.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  textAlign: 'center',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};

function App() {
  const [formData, setFormData] = useState({
    cliente: "1",
    nombre: "",
    apellido: "",
    monto: "",
    moneda: "MXN",
    invoice: null,
    isUnique: true,
    descripcion: "Donación"
  });

  const [responseData, setResponseData] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://neurotelco.pagoralia.live/api/v3/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 095f7df5e6fa01017d18b394678e2b0483ae79c5ab5a61be6556c8d09bccdd65'
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        console.error('Error en la respuesta:', response.status);     
        return;
      }

      const data = await response.json();
      console.log('Datos de la respuesta:', data.redirect_url);

      setResponseData(data);

      if (data.data.redirect_url) {
        console.log('URL de redirección:', data.data.redirect_url);
        window.location.replace(data.data.redirect_url);
      }
    } catch (error) {
      console.error('Error al enviar datos:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
      <ThemeProvider theme={theme}>
              <Typography variant="h3" style={{ padding: 20, marginTop: 10 }}>Ingrese el monto de su Donaci&oacute;n</Typography>
            </ThemeProvider>
        <form onSubmit={handleSubmit}>         
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cliente"
                name="cliente"
                value={formData.cliente}
                onChange={handleChange}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Apellido"
                name="apellido"
                value={formData.apellido}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Monto $"
                name="monto"
                value={formData.monto}
                onChange={handleChange}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                label="Moneda"
                name="moneda"
                value={formData.moneda}
                onChange={handleChange}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Button type="submit" variant="contained" color="primary" style={{ marginTop: 20 }}>
            Enviar
          </Button>
        </form>

        {responseData && (
          <div style={{ marginTop: 20 }}>
            <h2 style={{textAlign:'center'}}>GRACIAS POR SU DONACI&Oacute;N!!!</h2>
            {/* <pre>{JSON.stringify(responseData, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(responseData.data.redirect_url)}</pre> */}
          </div>
        )}
      </Paper>
    </Container>
  );
}

export default App;
